export const alt_letters = [
  'Unsent messages are the ghosts of conversations that haunt us.',
  'Every unsent message holds a story left untold.',
  'Behind every unsent message lies an untold emotion.',
  'And so it seems, I must always write you letters that I can never send. - Sylvia Plath',
  "I'm starting to think you're not coming back and i wish i knew what went wrong.",
  "All i've ever known is about leaving or being left - Carrie Ryan",
  'and then... we never spoke again.',
  "If i disappear, know that i'm okay.",
  'Silence is an answer too.',
  'People go but how they left always stay.',
  "And now you're just a stranger with all my secrets",
  'Unsent letters i wrote to you, now lying in drawer smells like you. - Rikki',
  'All the unsent letters were addressed to your heart, the one you chose to break from the start - vail@writes',
];
